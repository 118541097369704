import { useEffect, useRef, useState } from 'react';
import './video-player.scss';
import Loader from '../loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import config, { eventNames as event } from '../../config';
import { replacePlayoutURLValues } from '../../api/platform';
import { updateVideoStarted } from '../../store/slices/common-slice';
import { log } from '../../logger';
import Hls from 'hls.js';

export const VideoPlayer = (props) => {
  const videoRef = useRef(null);

  const currentChannel = useSelector((state) => state.currentChannel);
  const videoStarted = useSelector((state) => state.videoStarted);
  const dispatch = useDispatch();
  const [isPendding, setIsPendding] = useState(true);

  console.log(`state now ${isPendding}`);

  useEffect(() => {
    // dispatch(updateVideoStarted(0));

    // videoRef.current.load();

    const playoutURL = replacePlayoutURLValues(currentChannel.default_url);

    const video = videoRef.current;
    const hls = new Hls();

    // setIsPendding(true);
    if (!video.paused) video.pause();
    if (video) {
      video.addEventListener('waiting', () => {
        console.log(`changing pending waiting`);
        setIsPendding(true);
      });
      video.addEventListener('canplay', () => {
        console.log(`changing pending canplay`);
        setIsPendding(false);
      });

      if (video) {
        if (Hls.isSupported()) {
          hls.loadSource(playoutURL);
          hls.attachMedia(video);
          console.log(playoutURL, 'playoutURL stream');
          hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            console.log('video and hls.js are now bound together !');
          });
          hls.on(Hls.Events.MANIFEST_LOADED, (data, data2) => {
            console.log('HLS manifest loaded', data, data2, data2.captions, data2.subtitles);
          });
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            var isPlaying =
              video.currentTime > 0 &&
              !video.paused &&
              !video.ended &&
              video.readyState > video.HAVE_CURRENT_DATA;

            if (!isPlaying) {
              video
                .play()
                .then(() => {
                  // setIsPendding(false);
                  setTimeout(() => {
                    dispatch(updateVideoStarted(1));
                  }, 5000);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
          hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log('fatal media error encountered, try to recover');
                  hls.recoverMediaError();
                  break;
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.error('fatal network error encountered', data);
                  // All retries and media options have been exhausted.
                  // Immediately trying to restart loading could cause loop loading.
                  // Consider modifying loading policies to best fit your asset and network
                  // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
          });
        }
      }
    }
    return () => {
      // video.removeEventListener('waiting');
      // video.removeEventListener('canplay');
      hls.destroy();
    };
  }, [currentChannel]);

  console.log(`rendering video player`);
  return (
    <div className={'video-player'}>
      <div className="video">
        <video ref={videoRef} className="video"></video>
      </div>
      {isPendding && <Loader></Loader>}
    </div>
  );
};

export default VideoPlayer;
