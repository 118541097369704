import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAppHeartBeatCount,
  setLeftScrollEndTilePosition,
  setLeftScrollStartTilePosition,
  setPreviousPageName,
  setRightScrollEndTilePosition,
  setRightScrollStartTilePosition
} from './store/slices/common-slice';
import store from '../src/store/store';
import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
  apiKey: 'AIzaSyAD-7sNdUtpVBGpsHdqh7tKsYFw1YV9QJ4',
  authDomain: 'samsung-news-tv.firebaseapp.com',
  projectId: 'samsung-news-tv',
  storageBucket: 'samsung-news-tv.appspot.com',
  messagingSenderId: '501093951751',
  appId: '1:501093951751:web:c212903afffeccdce57da2',
  measurementId: 'G-RJ2K86CR6N'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const sessionId = uuidv4();
console.log('session id', sessionId);

export function log(
  event,
  data = {
    channel: null
  }
) {
  const state = store.getState();
  let eventData = {};
  const currentChannel = state.currentChannel;
  const prevChannel = state.previouslyRunningChannel;
  const underChannel = state.currentChannel;
  const currentlyRunningProgram = state.currentlyRunningProgram;
  const currentlyFocusedProgram = state.currentlyFocusedProgram;
  const currentlySelectedTilePosition = state.currentlySelectedTilePosition;
  const previouslySelectedTilePosition = state.previouslySelectedTilePosition;
  const drawerExitReason = state.drawerExitReason;
  const channelExitReason = state.channelExitReason;
  const channelEntryPoint = state.channelEntryPoint;
  const currentlySelectedProgram = state.currentlySelectedProgram;
  const previouslyRunningProgram = state.previouslyRunningProgram;
  const appExitReason = state.appExitReason;
  const scrollEndReason = state.scrollEndReason;
  const appEntryPoint = state.appEntryPoint;
  const appHeartBeatCount = state.appHeartBeatCount;
  const currentlyRunningProgramPlayedTime = state.currentlyRunningProgramPlayedTime;
  const totalDrawerTiles = state.totalDrawerTiles;
  const rightscrollEndTilePosition = state.rightscrollEndTilePosition;
  const leftscrollEndTilePosition = state.leftscrollEndTilePosition;
  const leftscrollStartTilePosition = state.leftscrollStartTilePosition;

  const rightscrollStartTilePosition = state.rightscrollStartTilePosition;
  const currentDrawerTilePosition = state.currentDrawerTilePosition;
  const previousDrawerTilePosition = state.previousDrawerTilePosition;
  const previousPageName = state.previousPageName;
  const currentlyFocusedChannel = state.currentlyFocusedChannel;
  eventData.SID = sessionId;
  switch (event) {
    case 'Start_App':
      eventData.CI = currentChannel?.service_id ?? '';
      eventData.CN = currentChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      eventData.EP = appEntryPoint ?? '';
      break;
    case 'Exit_App':
      eventData.CI = currentChannel?.service_id ?? '';
      eventData.CN = currentChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      eventData.EXIT = appExitReason ?? '';
      break;
    case 'Start_Channel':
      eventData.CI = currentChannel?.service_id;
      eventData.CN = currentChannel?.name;
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      eventData.EP = channelEntryPoint ?? '';
      break;
    case 'End_Channel':
      eventData.CI = currentChannel?.service_id;
      eventData.CN = currentChannel?.name;
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      eventData.EXIT = channelExitReason ?? '';
      break;
    case 'Start_Program':
      eventData.CI = currentChannel?.service_id ?? '';
      eventData.CN = currentChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      break;
    case 'End_Program':
      eventData.CI = currentChannel?.service_id ?? '';
      eventData.CN = currentChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      eventData.PLENGTH = currentlyRunningProgram?.duration ?? '';
      eventData.PTIME = currentlyRunningProgramPlayedTime ?? '';
      break;
    case 'HeartBeat':
      store.dispatch(setAppHeartBeatCount(appHeartBeatCount + 1));
      eventData.CI = currentChannel?.service_id ?? '';
      eventData.CN = currentChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.GID = currentChannel?.genre?.id ?? '';
      eventData.NTH = appHeartBeatCount ?? '';
      console.log(appHeartBeatCount, 'appHeartBeatCount');
      break;
    case 'Drawer_Content_Select':
      eventData.UCI = underChannel?.service_id ?? '';
      eventData.UCN = underChannel?.name ?? '';
      eventData.UPI = currentlyRunningProgram?.id ?? '';
      eventData.UPN = currentlyRunningProgram?.title ?? '';
      eventData.UGID = underChannel?.genre.id ?? '';
      eventData.SCI = data?.channel?.service_id ?? '';
      eventData.SCN = data?.channel?.name ?? '';
      eventData.SPI = currentlyFocusedProgram?.id ?? '';
      eventData.SPN = currentlyFocusedProgram?.title ?? '';
      eventData.SGID = data?.channel?.genre?.id ?? '';
      eventData.TTILE = totalDrawerTiles ?? '';
      eventData.PTP = previouslySelectedTilePosition ?? '';
      eventData.CTP = currentlySelectedTilePosition ?? '';
      break;
    case 'Drawer_Content_Impression':
      eventData.UCI = underChannel?.service_id ?? '';
      eventData.UCN = underChannel?.name ?? '';
      eventData.UPI = currentlyRunningProgram?.id ?? '';
      eventData.UGID = underChannel?.genre?.id ?? '';
      eventData.CI = data?.channel?.service_id ?? '';
      eventData.CN = data?.channel?.name ?? '';
      eventData.PI = currentlyFocusedProgram?.id ?? '';
      eventData.PN = currentlyFocusedProgram?.title ?? '';
      eventData.GID = data?.channel?.genre?.id ?? '';
      eventData.TTILE = totalDrawerTiles ?? '';
      eventData.TP = data?.currentDrawerTilePosition ?? '';
      break;
    case 'Drawer_Exit':
      eventData.UCI = underChannel?.service_id ?? '';
      eventData.UCN = underChannel?.name ?? '';
      eventData.UPI = currentlyRunningProgram?.id ?? '';
      eventData.UPN = currentlyRunningProgram?.title ?? '';
      eventData.UGID = underChannel?.genre.id ?? '';
      eventData.CI = currentlyFocusedChannel?.service_id ?? '';
      eventData.CN = currentlyFocusedChannel?.name ?? '';
      eventData.PI = currentlyFocusedProgram?.id ?? '';
      eventData.PN = currentlyFocusedProgram?.title ?? '';
      eventData.GID = currentlyFocusedChannel?.genre?.id ?? '';
      eventData.EXIT = drawerExitReason ?? '';
      break;
    case 'Drawer_Start':
      eventData.UCI = underChannel?.service_id ?? '';
      eventData.UCN = underChannel?.name ?? '';
      eventData.UPI = currentlyRunningProgram?.id ?? '';
      eventData.UPN = currentlyRunningProgram?.title ?? '';
      eventData.UGID = underChannel?.genre.id ?? '';
      eventData.CI = underChannel?.service_id ?? '';
      eventData.CN = underChannel?.name ?? '';
      eventData.PI = currentlyFocusedProgram?.id ?? '';
      eventData.PN = currentlyFocusedProgram?.title ?? '';
      eventData.GID = underChannel?.genre?.id ?? '';
      break;
    case 'Drawer_Scroll_Right':
      eventData.TTILE = totalDrawerTiles ?? '';
      eventData.STP = rightscrollStartTilePosition ?? '';
      eventData.ETP = rightscrollEndTilePosition ?? '';
      eventData.END = scrollEndReason ?? '';
      store.dispatch(setRightScrollStartTilePosition(null));
      store.dispatch(setRightScrollEndTilePosition(null));
      break;
    case 'Drawer_Scroll_Left':
      eventData.TTILE = totalDrawerTiles ?? '';
      eventData.STP = leftscrollStartTilePosition ?? '';
      eventData.ETP = leftscrollEndTilePosition ?? '';
      eventData.END = scrollEndReason ?? '';
      store.dispatch(setLeftScrollStartTilePosition(null));
      store.dispatch(setLeftScrollEndTilePosition(null));

      break;
    case 'Channel_Page':
      eventData.CI = underChannel?.service_id ?? '';
      eventData.CN = underChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.PCI = prevChannel?.service_id ?? '';
      eventData.PCN = prevChannel?.name ?? '';
      eventData.PPI = previouslyRunningProgram?.id ?? '';
      eventData.PPN = previouslyRunningProgram?.title ?? '';
      eventData.PP = previousPageName ?? '';
      store.dispatch(setPreviousPageName(event));
      break;
    case 'Channel_Preview_Page':
      eventData.CI = underChannel?.service_id ?? '';
      eventData.CN = underChannel?.name ?? '';
      eventData.PI = currentlyRunningProgram?.id ?? '';
      eventData.PN = currentlyRunningProgram?.title ?? '';
      eventData.EP = appEntryPoint ?? ''; // missing
      eventData.PP = previousPageName ?? '';
      store.dispatch(setPreviousPageName(event));

      break;
    default:
      break;
  }
  logEvent(analytics, event, eventData);
  console.log('Logged event is ', event, eventData);

  if (event === 'Exit_App') {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('resolve log promise promise');
        resolve();
      }, 300);
    });
  }
}
