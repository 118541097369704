import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tooltipTitle: '',
  channelIdx: 0,
  focusIdx: 0,
  channelDrawerVisible: 0,
  currentChannel: '',
  firstTimeUserGuide: '',
  scheduleData: '',
  videoStarted: 0,
  exitPopup: 0,
  currentlyRunningProgram: null,
  currentlyRunningProgramPlayedTime: null,
  currentlyFocusedProgram: null,
  currentlySelectedProgram: null,
  currentlySelectedTilePosition: null,
  previouslyRunningChannel: null,
  previouslyRunningProgram: null,
  previouslySelectedTilePosition: null,
  appEntryPoint: null,
  appExitReason: null,
  scrollEndReason: null,
  drawerExitReason: null,
  channelExitReason: null,
  channelEntryPoint: null,
  appHeartBeatCount: 0,
  totalDrawerTiles: 0,
  leftscrollEndTilePosition: null,
  leftscrollStartTilePosition: null,
  rightscrollEndTilePosition: null,
  rightscrollStartTilePosition: null,
  currentDrawerTilePosition: null,
  previousDrawerTilePosition: null,
  previousPageName: null,
  showUpSpeedTooltip: null,
  currentlyFocusedChannel: null,
  oneMinuteCheck: null,
  onBoardingStates: {
    LEFT_RIGHT: {
      viewed: false
    },
    SELECT: {
      viewed: false
    },
    CHANNEL_UP_DOWN: {
      viewed: false
    },
    SELECT_UP_DOWN: {
      viewed: false
    },
    UP_TO_SPEED: {
      viewed: false
    }
  },
  isLogingPageVisible: !localStorage.getItem('isUserUsedNewsTV') || !webapis.sso.getLoginStatus()
};
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateShowUpSpeedTooltip(state, action) {
      state.showUpSpeedTooltip = action.payload;
    },
    setOnBoardingStates(state, action) {
      console.log(state.onBoardingStates, action.payload, 'state. onBoardingStates');
      state.onBoardingStates = { ...state.onBoardingStates, ...action.payload };
    },
    setOneMinuteCheck(state, action) {
      state.oneMinuteCheck = action.payload;
    },
    setDrawerExitReason(state, action) {
      state.drawerExitReason = action.payload;
    },
    setChannelExitReason(state, action) {
      state.channelExitReason = action.payload;
    },
    setChannelEntryPoint(state, action) {
      state.channelEntryPoint = action.payload;
    },
    setCurrentlySelectedTilePosition(state, action) {
      state.previouslySelectedTilePosition = state.currentlySelectedTilePosition;
      state.currentlySelectedTilePosition = action.payload;
    },
    setTotalDrawerTiles(state, action) {
      state.totalDrawerTiles = action.payload;
    },
    setLeftScrollEndTilePosition(state, action) {
      state.leftscrollEndTilePosition = action.payload;
      console.log(action.payload, 'Drawer_Scroll left end');
    },
    setLeftScrollStartTilePosition(state, action) {
      state.leftscrollStartTilePosition = action.payload;
      console.log(action.payload, 'Drawer_Scroll left start');
    },
    setRightScrollEndTilePosition(state, action) {
      state.rightscrollEndTilePosition = action.payload;
      console.log(action.payload, 'Drawer_Scroll right end');
    },
    setRightScrollStartTilePosition(state, action) {
      state.rightscrollStartTilePosition = action.payload;
      console.log(action.payload, 'Drawer_Scroll right start');
    },
    setCurrentDrawerTilePosition(state, action) {
      state.currentDrawerTilePosition = action.payload;
    },
    setPreviousDrawerTilePosition(state, action) {
      state.previousDrawerTilePosition = action.payload;
    },
    setPreviousPageName(state, action) {
      state.previousPageName = action.payload;
    },
    setCurrentlyFocusedChannel(state, action) {
      state.currentlyFocusedChannel = action.payload;
    },
    setAppHeartBeatCount(state, action) {
      state.appHeartBeatCount = action.payload;
    },
    setCurrentlyRunningProgramPlayedTime(state, action) {
      state.currentlyRunningProgramPlayedTime = action.payload;
    },
    setCurrentRunningProgram(state, action) {
      state.currentlyRunningProgram = action.payload;
    },
    setCurrentFocusedProgram(state, action) {
      state.currentlyFocusedProgram = action.payload;
    },
    setCurrentSelectedProgram(state, action) {
      state.currentlySelectedProgram = action.payload;
    },
    setPreviouslyRunningChannel(state, action) {
      state.previouslyRunningChannel = action.payload;
    },
    setPreviouslyRunningProgram(state, action) {
      state.previouslyRunningProgram = action.payload;
    },
    setScrollEndReason(state, action) {
      state.scrollEndReason = action.payload;
    },
    updateTooltipTitle(state, action) {
      state.tooltipTitle = action.payload;
    },
    updateChannelIdx(state, action) {
      state.channelIdx = action.payload;
    },
    updateFocusIdx(state, action) {
      state.focusIdx = action.payload;
    },
    updateChannelDrawerVisible(state, action) {
      state.channelDrawerVisible = action.payload;
    },
    updateCurrentChannel(state, action) {
      state.currentChannel = action.payload;
    },
    updateFirstTimeUserGuide(state, action) {
      state.firstTimeUserGuide = action.payload;
    },
    updateScheduleData(state, action) {
      state.scheduleData = action.payload;
    },
    updateVideoStarted(state, action) {
      state.videoStarted = action.payload;
    },
    updateExitPopup(state, action) {
      state.exitPopup = action.payload;
    },
    updateIsLoginPageVisible(state, action) {
      state.isLogingPageVisible = action.payload;
    }
  }
});
export const {
  setChannelEntryPoint,
  setTotalDrawerTiles,
  setScrollEndTilePosition,
  setScrollStartTilePosition,
  setCurrentDrawerTilePosition,
  setPreviousDrawerTilePosition,
  setPreviousPageName,
  setCurrentlyFocusedChannel,
  updateTooltipTitle,
  setChannelExitReason,
  setAppHeartBeatCount,
  updateChannelIdx,
  updateChannelDrawerVisible,
  updateCurrentChannel,
  updateFirstTimeUserGuide,
  setOneMinuteCheck,
  updateFocusIdx,
  updateScheduleData,
  updateVideoStarted,
  updateExitPopup,
  setCurrentRunningProgram,
  setPreviouslyRunningChannel,
  setCurrentlyRunningProgramPlayedTime,
  setOnBoardingStates,
  updateShowUpSpeedTooltip,
  updateIsLoginPageVisible,
  setPreviouslyRunningProgram,
  setCurrentFocusedProgram,
  setCurrentSelectedProgram,
  setCurrentlySelectedTilePosition,
  setDrawerExitReason,
  setScrollEndReason,
  setLeftScrollEndTilePosition,
  setLeftScrollStartTilePosition,
  setRightScrollEndTilePosition,
  setRightScrollStartTilePosition
} = commonSlice.actions;
export default commonSlice.reducer;
