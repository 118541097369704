import './program-info.scss';
import liveIcon from '../../assets/live-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { scheduleFormatter } from '../../util';
import {
  setCurrentRunningProgram,
  setCurrentlyRunningProgramPlayedTime,
  setPreviouslyRunningProgram
} from '../../store/slices/common-slice';
import { useEffect, useState } from 'react';

function ProgramInfo(props) {
  const dispatch = useDispatch();
  const className = 'program-info ' + props.className;
  const currentChannel = useSelector((state) => state.currentChannel);
  const scheduleData = useSelector((state) => state.scheduleData);
  const [localScheduleData, setLocalScheduleData] = useState(scheduleData);
  const currentlyRunningProgram = useSelector((state) => state.currentlyRunningProgram);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [focusIdx, setVisibilityState] = useState(props.focusIdx);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [duration, setDuration] = useState(null);
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  const [programTitle, setProgramTile] = useState(null);
  // let progressBarWidth = 0;
  useEffect(() => {
    setLocalScheduleData(scheduleData);
  }, []);

  useEffect(() => {
    if (scheduleData) {
      const channelSchedule = scheduleData.find((s) => s.service_id === currentChannel.service_id);
      let lduration, lendtime, lstarttime, lcurrentTime, lprogressBarWidth;

      if (channelSchedule) {
        for (let x in channelSchedule.content) {
          lstarttime = new Date(channelSchedule.content[x].starttime);
          lduration = +channelSchedule.content[x].duration;
          lendtime = new Date(lstarttime.getTime() + lduration * 1000);
          lcurrentTime = new Date();
          if (
            lcurrentTime.getTime() >= lstarttime.getTime() &&
            lendtime.getTime() > lcurrentTime.getTime()
          ) {
            dispatch(setPreviouslyRunningProgram(currentlyRunningProgram));
            dispatch(setCurrentRunningProgram(channelSchedule.content[x]));
            setProgramTile(channelSchedule.content[x].title);
            setProgressBarWidth((lcurrentTime - lstarttime) / (lduration * 1000));
            setEndTime(lendtime);
            setStartTime(lstarttime);
            dispatch(setCurrentlyRunningProgramPlayedTime(progressBarWidth * 100));

            break;
          }
        }
      }
    }
  }, [focusIdx, scheduleData, currentChannel]);

  return (
    <div className={className}>
      {
        <div className="channel-info">
          <div className="channel-icon">
            <img src={props.iconUrl} alt="" />
          </div>
          <div className="channel-name">{props.channelName}</div>
        </div>
      }
      {<div className="show-name">{programTitle}</div>}
      {
        <div className="show-duration">
          <div className="time-wrapper">
            <div className="live-icon">
              <img src={liveIcon} alt="" />
            </div>
            {startTime && <div className="show-time">{scheduleFormatter(startTime, endTime)}</div>}
          </div>
          {scheduleData && (
            <div className="progress-bar">
              <div className="bar"></div>
              <div className="bar-fill" style={{ width: `${progressBarWidth * 100}%` }}></div>
            </div>
          )}
        </div>
      }
    </div>
  );
}

export default ProgramInfo;
