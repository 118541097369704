import select_img from './assets/tooltip-icons/select.png';
import channel_up_down_img from './assets/tooltip-icons/channelupdown.png';
import select_up_down_img from './assets/tooltip-icons/selectupdown.png';
import up_to_speed_img from './assets/tooltip-icons/check.png';
import left_right_img from './assets/tooltip-icons/tooltip_1.svg';

let config = {
  DEBUG: true,
  WEATHER_API_INTERVAL: 3600000,
  HEARTBEAT_INTERVAL: 180000,
  PROXY_URL: `https://news-tv-dev.umd.guide/osb-proxy`,
  EXTENSION_SERVER: `https://news-tv-dev.umd.guide`,
  USE_PROXY: true,
  TOOLTIP_TIMEOUT: 5000,
  nowPlayText: 'Now Playing'
};

export const TOOLTIP_TEXTS = {
  LEFT_RIGHT: {
    text: 'Press Left/Right to navigate different channels.',
    img: left_right_img,
    jsx_text: (
      <div>
        Press <b>Left/Right</b> to navigate different channels.
      </div>
    )
  },
  SELECT: {
    text: 'Press Select to navigate different channels.',
    img: select_img,
    jsx_text: (
      <div>
        Press <b>Select</b> to navigate different channels.
      </div>
    )
  },
  CHANNEL_UP_DOWN: {
    text: 'Press Channel Up/Down to change the channel.',
    img: channel_up_down_img,
    jsx_text: (
      <div>
        Press <b>Channel Up/Down</b> to navigate different channels.
      </div>
    )
  },
  SELECT_UP_DOWN: {
    text: 'Press Select, Up, or Down to show channel options.',
    img: select_up_down_img,
    jsx_text: (
      <div>
        Press <b>Select</b>, <b>Up</b>, or <b>Down</b> to navigate different channels.
      </div>
    )
  },
  UP_TO_SPEED: {
    text: 'Now you’re up to speed. We hope you enjoy the Samsung News App!',
    img: up_to_speed_img,
    jsx_text: <div>Now you’re up to speed. We hope you enjoy the Samsung News App!</div>
  }
};
export const DEFAULT_TIME = '9:00AM–11:30AM';

export const eventNames = {
  EXIT_APP: 'Exit_App',
  START_APP: 'Start_App',
  HEART_BEAT: 'HeartBeat',
  DRAWER_CONTENT_EXPRESSION: 'Drawer_Content_Impression',
  DRAWER_EXIT: 'Drawer_Exit',
  DRAWER_START: 'Drawer_Start',
  DRAWER_SCROLL_RIGHT: 'Drawer_Scroll_Right',
  DRAWER_SCROLL_LEFT: 'Drawer_Scroll_Left',
  END_CHANNEL: 'End_Channel',
  START_CHANNEL: 'Start_Channel',
  DRAWER_CONTENT_SELECT: 'Drawer_Content_Select',
  CHANNEL_PAGE: 'Channel_Page',
  CHANNEL_PREVIEW_PAGE: 'Channel_Preview_Page',
  START_PROGRAM: 'Start_Program',
  END_PROGRAM: 'End_Program'
};
export const TOOLTIP_VIEWED = 'tooltips-viewed';

export default config;
