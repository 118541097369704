import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoPlayer from './components/video-player/video-player';
import './main.scss';
import ChannelDrawer from './components/channel-drawer/channel-drawer';
import LoginPage from './components/loginPage/loginPage';
import BasicPopup from './components/basic-popup/basic-popup';
import { weather } from './api/weather';
import { epgData, scheduleDataRes } from './api/channels';
import bootupImg from './assets/NewsSplashScreen.png';
import { SERVICE_IDS_TO_SHOW } from './data/channels_to_include';
import {
  updateFirstTimeUserGuide,
  updateScheduleData,
  updateTooltipTitle,
  updateChannelDrawerVisible,
  updateCurrentChannel,
  updateExitPopup,
  updateShowUpSpeedTooltip,
  updateIsLoginPageVisible,
  updateChannelIdx,
  setTotalDrawerTiles,
  updateFocusIdx,
  setChannelExitReason
} from './store/slices/common-slice';
import config, { TOOLTIP_VIEWED, eventNames as appEvent } from './config';
import { log } from './logger';
import { getChannelColors, getServerSchedules } from './api/platform';

function App() {
  const dispatch = useDispatch();
  const [weatherInfo, setWeatherInfo] = useState({ temperature: null });
  const [isChannelsDataReceived, setIsChannelsDataReceived] = useState(false);
  const [isScheduleDataReceived, setIsScheduleDataReceived] = useState(false);

  const isLogingPageVisible = useSelector((state) => state.isLogingPageVisible);
  const scheduleData = useSelector((state) => state.scheduleData);
  const exitPopup = useSelector((state) => state.exitPopup);
  const channelDrawerVisible = useSelector((state) => state.channelDrawerVisible);
  const currentChannel = useSelector((state) => state.currentChannel);
  const channels = useRef();
  const schedules = useRef();
  let HeartBeatInterval = null;
  let WeatherInterval = null;
  const handleButtonDown = (event) => {
    event.preventDefault();
    if (exitPopup || channelDrawerVisible) {
      return;
    }
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
      dispatch(updateChannelDrawerVisible(1));
      dispatch(updateShowUpSpeedTooltip(1));
      return;
    }
    if (event.key === 'XF86Back') {
      dispatch(updateExitPopup(1));
      console.log('exit event');
    }
  };
  const handleAppGroundCheck = () => {
    console.log('hidden is running');
    if (document.hidden) {
      webapis.avplay.close();
      console.log('app is hidden');
      if (webapis.sso.getLoginStatus() === 0) {
        dispatch(updateIsLoginPageVisible(0));
      }
      dispatch(setChannelExitReason('App Exited'));
      log(appEvent.END_CHANNEL);
      log(appEvent.EXIT_APP);
    } else {
      console.log('app is not hidden');

      // app is in the foreground
      log(appEvent.START_APP);
    }
  };

  useEffect(() => {
    epgData()
      .then(async (res) => {
        const channelsToSendForColors = res.map((channel) => {
          return {
            service_id: channel.service_id,
            image: channel.icons['70']
          };
        });

        const channelColors = await getChannelColors(channelsToSendForColors);
        console.log(`CHANNEL COLORS: ${JSON.stringify(channelColors, null, 2)}`);

        for (const channel of res) {
          channel.news_tv_color = channelColors
            .find((c) => c.service_id === channel.service_id)
            ?.color?.map((componentValue) => componentValue * 0.85);
        }

        channels.current = res;
        dispatch(setTotalDrawerTiles(channels.length));

        setIsChannelsDataReceived(true);
        if (localStorage.getItem('lastPlayedChannelId')) {
          let lastPlayedChannelIdx = SERVICE_IDS_TO_SHOW.indexOf(
            localStorage.getItem('lastPlayedChannelId')
          );
          if (lastPlayedChannelIdx >= res.length || !lastPlayedChannelIdx) lastPlayedChannelIdx = 0;
          dispatch(updateCurrentChannel(res[lastPlayedChannelIdx]));
          dispatch(updateChannelIdx(lastPlayedChannelIdx));
          dispatch(updateFocusIdx(lastPlayedChannelIdx));
        } else {
          dispatch(updateCurrentChannel(res[0]));
        }
        if (config.DEBUG) console.log('epgData in main', res);
      })
      .catch((err) => {
        setIsChannelsDataReceived(false);
        console.log('channel data not received', err);
      });
    getServerSchedules()
      .then((res) => {
        localStorage.setItem('scheduleData', JSON.stringify(res));
        dispatch(updateScheduleData(res));
        setIsScheduleDataReceived(1);
      })
      .catch((err) => {
        setIsScheduleDataReceived(0);
        console.log('sedhule data not received');
      });

    // document.addEventListener('keydown', handleButtonDown);
    // document.addEventListener('visibilitychange', handleAppGroundCheck);
    dispatch(updateFirstTimeUserGuide(localStorage.getItem('firstTimeUserGuide')));
    console.log(localStorage.getItem('firstTimeUserGuide'), 'firstTimeUserGuide localStorage');
    if (!isLogingPageVisible && localStorage.getItem('firstTimeUserGuide') !== TOOLTIP_VIEWED) {
      dispatch(updateChannelDrawerVisible(1));
    }
    // return () => {
    //   document.removeEventListener('keydown', handleButtonDown);
    //   document.removeEventListener('visibilitychange', handleAppGroundCheck);
    // };
  }, [isLogingPageVisible]);

  useEffect(() => {
    clearInterval(HeartBeatInterval);
    log(appEvent.HEART_BEAT);
    HeartBeatInterval = setInterval(() => {
      log(appEvent.HEART_BEAT);
    }, config.HEARTBEAT_INTERVAL);

    weather()
      .then((res) => {
        setWeatherInfo(res);
        if (config.DEBUG) console.log('[] weather');
      })
      .catch(() => {
        if (config.DEBUG) console.log('weather error');
      });
    clearInterval(WeatherInterval);
    WeatherInterval = setInterval(() => {
      weather()
        .then((res) => {
          setWeatherInfo(res);
          if (config.DEBUG) console.log('[] weather');
        })
        .catch(() => {
          if (config.DEBUG) console.log('weather error');
        });
    }, config.WEATHER_API_INTERVAL);
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', handleButtonDown);
    document.addEventListener('visibilitychange', handleAppGroundCheck);
    return () => {
      document.removeEventListener('keydown', handleButtonDown);
      document.removeEventListener('visibilitychange', handleAppGroundCheck);
    };
  }, [exitPopup, channelDrawerVisible, isLogingPageVisible]);

  // useEffect(() => {
  //   CDRef.current;
  //   document.addEventListener('keydown', handleButtonDown);
  //   document.addEventListener('visibilitychange', handleAppGroundCheck);
  //   return () => {
  //     document.removeEventListener('keydown', handleButtonDown);
  //     document.removeEventListener('visibilitychange', handleAppGroundCheck);
  //   };
  // }, []);

  if (isLogingPageVisible) return <LoginPage></LoginPage>;
  else if (!isChannelsDataReceived) return <img src={bootupImg} alt="" />;
  else
    return (
      <div className="App">
        <div className="news-tv">
          <VideoPlayer />
          <ChannelDrawer channels={channels.current} weatherInfo={weatherInfo}></ChannelDrawer>
          {exitPopup && <BasicPopup buttonList={['Cancel', 'Exit']}></BasicPopup>}
        </div>
      </div>
    );
}

export default App;
