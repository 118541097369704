export const timeFormatter = (time) => {
  if (!time) return;
  return new Date(time).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

export const scheduleFormatter = (startTime, endTime) => {
  let start = timeFormatter(startTime);
  let end = timeFormatter(endTime);

  if (start.includes('PM') && end.includes('PM')) {
    start = start.replace('PM', '');
  }

  if (start.includes('AM') && end.includes('AM')) {
    start = start.replace('AM', '');
  }

  return `${start}–${end}`;
};

export const stateNameToAbbreviation = (fullSateName) => {
  const statesMap = {
    Arizona: 'AZ',
    Alabama: 'AL',
    Alaska: 'AK',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
    Haryana: 'HR' // for our location
  };
  return statesMap[fullSateName] || fullSateName;
};
