import config from '../config';

const getDUID = () => window.webapis.productinfo.getDuid();
const getAToken = () => window.webapis.was.getAtoken();

import axios from 'axios';
import jszip from 'jszip';
import { SERVICE_IDS_TO_SHOW } from '../data/channels_to_include';

const modelCode = () => webapis.productinfo.getModelCode();

const serverVersion = () => webapis.productinfo.getSmartTVServerVersion();
const softwareVersion = () => webapis.productinfo.getFirmware();
const smartTVClient = () =>
  `OTN-FW/${softwareVersion()}+T-INFOLINK/${serverVersion()} ${webapis.productinfo.getDuid()}`;

const sendGetRequest = async (url, headers, configuration = {}) => {
  let res;
  if (config.DEBUG) console.log(`URL: ${url}\nHEADERS: ${JSON.stringify(headers)}`);
  if (config.USE_PROXY) {
    res = await axios.post(
      config.PROXY_URL,
      { url, headers, method: 'GET', raw_response_data: true },
      configuration
    );
  } else {
    res = await axios.get(url, { headers });
  }

  if (res.status >= 400) {
    throw new Error(res.statusText);
  }

  return res.data;
};

export const geolocate = async () => {
  const geoURL = webapis.productinfo.getSystemConfig(
    webapis.productinfo.ProductInfoConfigKey.CONFIG_GPMURL_KEY_GEO
  );

  const headers = {
    Accept: 'application/json',
    'Accept-Encoding': 'gzip, deflate',
    'Cache-Control': 'no-cache',
    Atoken: getAToken(),
    DUID: getDUID(),
    LocalTime: new Date().toISOString(),
    SmartTVClient: smartTVClient(), //'ONT-FW/T-J2MDCNC-1151.1+T-INFOLINK2020-1009+AppName/1.0',
    'X-Vd-Caller': 'com.samsung.tv.csa'
  };

  const url = `${geoURL}/geo/service/v2/geoipinfo`;

  const data = await sendGetRequest(url, headers);
  return data;
};

export const epg = async () => {
  const ontvURL = webapis.productinfo.getSystemConfig(
    webapis.productinfo.ProductInfoConfigKey.CONFIG_GPMURL_KEY_ONTV
  );

  const headers = {
    Accept: 'application/json',
    'Accept-Encoding': 'gzip, deflate',
    'Cache-Control': 'no-cache',
    Atoken: getAToken(),
    DUID: getDUID(),
    LocalTime: new Date().toISOString(),
    SmartTVClient: smartTVClient(), //'ONT-FW/T-J2MDCNC-1151.1+T-INFOLINK2020-1009+AppName/1.0',
    'X-Vd-Caller': 'slive-provider-daemon'
  };

  const url = `${ontvURL}/ontv/connected/channel/v2/providers?country=us&model=${modelCode()}&platform=FREESIA_PLATFORM_2018_0.1`;

  const data = await sendGetRequest(url, headers);
  return data;
};

export const getSchedules = async () => {
  const ontvURL = webapis.productinfo.getSystemConfig(
    webapis.productinfo.ProductInfoConfigKey.CONFIG_GPMURL_KEY_ONTV
  );

  const headers = {
    Accept: 'application/json',
    'Accept-Encoding': 'gzip, deflate',
    'Cache-Control': 'no-cache',
    Atoken: getAToken(),
    DUID: getDUID(),
    LocalTime: new Date().toISOString(),
    SmartTVClient: smartTVClient(), //'ONT-FW/T-J2MDCNC-1151.1+T-INFOLINK2020-1009+AppName/1.0',
    'X-Vd-Caller': 'slive-provider-daemon'
  };

  const url = `${ontvURL}/ontv/connected/channel/v2/all_content_list?country=us&model=${modelCode()}&platform=FREESIA_PLATFORM_2018_0.1`;

  const data = await sendGetRequest(url, headers);
  return data;
};

export const getServerSchedules = async () => {
  const ontvURL = webapis.productinfo.getSystemConfig(
    webapis.productinfo.ProductInfoConfigKey.CONFIG_GPMURL_KEY_ONTV
  );

  const url = `${ontvURL}/ontv/connected/channel/v2/all_content_list?country=us&model=${modelCode()}&platform=FREESIA_PLATFORM_2018_0.1`;

  const body = {
    gpm: ontvURL,
    ato: getAToken(),
    duid: getDUID(),
    stc: smartTVClient(),
    mc: modelCode(),
    svc_ids: SERVICE_IDS_TO_SHOW
  };

  const res = await axios.post(`${config.EXTENSION_SERVER}/epg`, body);

  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('failed to fetch schedule');
  }
};

export const getChannelColors = async (channels) => {
  const body = {
    channels
  };

  const res = await axios.post(`${config.EXTENSION_SERVER}/color-grabber`, body);

  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('failed to fetch schedule');
  }
};

export const retrieveAndUnpackSchedule = async (url) => {
  const zipData = await sendGetRequest(
    url,
    { 'Content-Type': 'application/zip' },
    { responseType: 'arraybuffer' }
  );
  // console.log(`downloaded the zip`);
  // console.log('schedule', zipData);
  const zip = await jszip.loadAsync(zipData);
  // console.log(`loaded the zip`);
  let fileInZip;
  zip.forEach((path, file) => {
    fileInZip = file;
  });
  const fileContent = await fileInZip.async('string');
  // console.log(`unzipped the zip data`);
  const data = JSON.parse(fileContent);
  return data;
};

export const channelList = async () => {
  return new Promise((resolve, reject) => {
    window.webapis.hybridchannelinfo.getChannelList(resolve, reject);
    // tizen.tvchannel.getChannelList(resolve, reject, 'ALL', 0, 50);
  });

  // return window.webapis.hybridchannelinfo.getOwnedChannelList();
};
const APP_DOMAIN = 'https%3A%2F%2Fwww.samsung.com%2Fus%2Fappstore%2Fapp.do%3FappId%3DG15147002586';
const APP_NAME = 'Samsung+TV+Plus';
export const replacePlayoutURLValues = (url) => {
  console.debug(`Raw Playout URL: ${url}`);
  let result = url.replace('%7BAPP_DOMAIN%7D', APP_DOMAIN);
  result = result.replace('%7BAPP_NAME%7D', APP_NAME);
  result = result.replace('%7BAPP_NAME%7D', APP_NAME);
  result = result.replace('%7BTARGETOPT%7D', webapis.adinfo.isLATEnabled() ? '1' : '0');
  result = result.replace('%7BPSID%7D', webapis.adinfo.getTIFA());
  console.debug(`Prepared Playout URL: ${result}`);
  return result;
};

// module.exports = { geolocate, epg, channelList };
