import { geolocate } from './platform';
import config from '../config';

export const weather = async () => {
  let locationData = await geolocate()
    .then(async (data) => {
      const latitude = data.rsp.location.latitude;
      const longitude = data.rsp.location.longitude;
      if (config.DEBUG) console.log(data.rsp.location, 'geolocate', typeof latitude);
      let weatherInfoLocal = { ...data.rsp.location };
      if (config.DEBUG) console.log(weatherInfoLocal, 'geolocate weather');
      await fetch(
        'https://api.weather.com/v3/wx/observations/current?geocode=' +
          latitude +
          ',' +
          longitude +
          '&units=e&language=en-US&format=json&apiKey=8ef8f7fc07f744deb8f7fc07f7a4de59'
      )
        .then((res) => res.json())
        .then((val) => {
          if (config.DEBUG) console.log(val, 'geolocate weather info');
          weatherInfoLocal = { ...weatherInfoLocal, ...val };
          if (config.DEBUG) console.log(weatherInfoLocal, 'geolocate fetch');
          // setWeatherInfo(weatherInfoLocal);
        })
        .catch((err) => {
          if (config.DEBUG) console.log('weather api not working', err);
        });
      return weatherInfoLocal;
    })
    .catch((err) => {
      console.log(err);
    });
  return locationData;
};
