import './tooltip.scss';
const ToolTip = (props) => {
  const className = 'tooltip ' + props.className;
  return (
    <div className={className}>
      <div className="tooltip-container">
        <div className="tooltip-img">
          <img src={props.icon} alt="" />
        </div>
        <div className="tooltip-text-container">
          <div className="title-text">Samsung News Tips</div>
          <div className="subtitle-text">{props.jsx_text}</div>
        </div>
      </div>
    </div>
  );
};

export default ToolTip;
