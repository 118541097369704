import './loginPage.scss';
import samsungNewsLogo from '../../assets/Samsung_News_Beta_logo_White.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsLoginPageVisible } from '../../store/slices/common-slice';
import config from '../../config';

function loginPage() {
  const isLogingPageVisible = useSelector((state) => state.isLogingPageVisible);
  const loginBtnTxt = webapis?.sso.getLoginStatus() ? 'Continue' : 'Sign In';
  const dispatch = useDispatch();
  const handleButtonDown = (event) => {
    if (!isLogingPageVisible) return;
    switch (event.key) {
      case 'Enter':
        localStorage.setItem('isUserUsedNewsTV', 1);
        if (webapis.sso.getLoginStatus()) {
          dispatch(updateIsLoginPageVisible(0));
        } else {
          var onsuccess = function (result) {
            if (webapis.sso.getLoginStatus()) {
              dispatch(updateIsLoginPageVisible(0));
            }
            if (config.DEBUG) console.log('success  ' + JSON.stringify(result));
          };
          var onerror = function (error) {
            if (config.DEBUG) console.log('error : ' + error);
          };
          webapis.sso.showAccountView('Samsung News', onsuccess, onerror);
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleButtonDown);
    return () => {
      document.removeEventListener('keydown', handleButtonDown);
    };
  }, [isLogingPageVisible]);
  return (
    <div className="loginPage-wrapper">
      <div className="loginPage">
        <div className="logo">
          <img src={samsungNewsLogo} alt="" />
        </div>
        <div className="text-info">
          <div className="text">Free round-the-clock news</div>
          <div className="sub-text">
            Stay up-to-date with breaking news, in-depth analysis, and live updates from trusted
            news outlets.
          </div>
        </div>
        <div className="login-btn">{loginBtnTxt}</div>
      </div>
    </div>
  );
}

export default loginPage;
