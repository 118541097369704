import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './channel-drawer.scss';
import samsungNewsLogo from '../../assets/Samsung_News_Beta_logo_White.svg';
import ToolTip from '../tooltip/tooltip';
import weatherIcon from '../../assets/Weather-icons/Cloudy.svg';
import ProgramInfo from '../program-info/program-info';
import { SERVICE_IDS_TO_SHOW } from '../../data/channels_to_include';

import {
  updateChannelDrawerVisible,
  updateChannelIdx,
  setChannelEntryPoint,
  updateCurrentChannel,
  updateFocusIdx,
  setChannelExitReason,
  updateTooltipTitle,
  updateFirstTimeUserGuide,
  setPreviouslyRunningChannel,
  setOneMinuteCheck,
  setOnBoardingStates,
  updateShowUpSpeedTooltip,
  setCurrentFocusedProgram,
  setCurrentSelectedProgram,
  setCurrentlyFocusedChannel,
  setCurrentlySelectedTilePosition,
  setDrawerExitReason,
  setRightScrollEndTilePosition,
  setRightScrollStartTilePosition,
  setLeftScrollEndTilePosition,
  setLeftScrollStartTilePosition,
  setScrollEndReason
} from '../../store/slices/common-slice';
import config, {
  TOOLTIP_TEXTS,
  TOOLTIP_VIEWED,
  DEFAULT_TIME,
  eventNames as appEvents
} from '../../config';
import { scheduleFormatter, stateNameToAbbreviation } from '../../util';
import { log } from '../../logger';

const ChannelItem = (props) => {
  const channels = props.channels;
  const isPlaying = props.isPlaying;
  const active = useSelector((state) => state.channelDrawerVisible);
  const isFocused = props.isFocused; // changing this to useState causes focus change to stop working
  const idx = props.idx;
  const element = props.element;
  const schedule = props.schedule;
  const dispatch = useDispatch();
  const titleRef = useRef();
  const [program, setProgram] = useState(null);
  const itemRef = useRef(null);
  let titleToShow = element.name;
  let timeString;
  let programPoster = '';
  let progressBarWidth = 0;

  const ismarqueed = () => {
    if (titleRef.current?.offsetWidth < titleRef?.current?.scrollWidth) return true;
    return false;
  };

  if (schedule) {
    const currentTime = new Date();
    let startTime, endTime, duration;
    let programTitle;

    for (let x in schedule.content) {
      startTime = new Date(schedule.content[x].starttime);
      duration = +schedule.content[x].duration;
      endTime = new Date(startTime.getTime() + duration * 1000);
      if (currentTime >= startTime && endTime > currentTime) {
        programTitle = schedule.content[x].title;
        progressBarWidth = (currentTime - startTime) / (duration * 1000);
        programPoster = schedule.content[x]?.poster?.url?.replace('%02d', '20');
        break;
      }
    }

    if (programTitle) {
      titleToShow = programTitle;
      timeString = scheduleFormatter(startTime, endTime);
    }
  } else {
    console.warn(`missing schedule for channel`);
  }

  function isElementInViewport(el) {
    var rect = el?.getBoundingClientRect();
    return (
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  useEffect(() => {
    setTimeout(() => {
      if (active === 1 && isElementInViewport(itemRef.current)) {
        console.log('Drawer_Content_Impression ', channels, props);
        log(appEvents.DRAWER_CONTENT_EXPRESSION, {
          channel: channels[props.tileIdx],
          currentDrawerTilePosition: props.tileIdx
        });
      }
    }, 5000);
  }, [active]);

  return (
    <li ref={itemRef} className={active && isFocused ? 'focus listItem' : 'listItem'}>
      <div className="channel-logo">
        <div
          className="logo"
          style={{
            backgroundColor: `rgb(${element?.news_tv_color.join(',')})`,
            objectFit: 'contain'
          }}>
          {isPlaying && <div className="now-playing">{config.nowPlayText}</div>}
          <img
            className={
              (isFocused || isPlaying) && programPoster
                ? 'hide-icon channel-icon'
                : 'show-icon channel-icon'
            }
            src={element?.icons['70']}
            alt=""
          />
          {programPoster && (
            <img
              className={('logo-img', !(isFocused || isPlaying) ? 'hide-prog' : 'hidefocus')}
              src={programPoster}
              alt=""
            />
          )}
          {schedule && (
            <div className="progress-bar">
              <div className="bar"></div>
              <div className="bar-fill" style={{ width: `${progressBarWidth * 100}%` }}></div>
            </div>
          )}
        </div>
      </div>
      <div className="channel-info">
        {isFocused && ismarqueed() ? (
          <marquee ref={titleRef} className="title">
            {titleToShow}
          </marquee>
        ) : (
          <div ref={titleRef} className="title">
            {titleToShow}
          </div>
        )}
        <div className="duration">{timeString}</div>
      </div>
    </li>
  );
};

const ChannelDrawer = (props) => {
  const channelIdx = useSelector((state) => state.channelIdx);
  const focusIdx = useSelector((state) => state.focusIdx);
  const showUpSpeedTooltip = useSelector((state) => state.showUpSpeedTooltip);
  const active = useSelector((state) => state.channelDrawerVisible);
  const currentChannel = useSelector((state) => state.currentChannel);
  const firstTimeUserGuide = useSelector((state) => state.firstTimeUserGuide);
  const scheduleData = useSelector((state) => state.scheduleData);
  const exitPopup = useSelector((state) => state.exitPopup);
  const videoStarted = useSelector((state) => state.videoStarted);
  const onBoardingStates = useSelector((state) => state.onBoardingStates);
  const oneMinuteCheck = useSelector((state) => state.oneMinuteCheck);
  const tooltipTitle = useSelector((state) => state.tooltipTitle);
  const channelDrawerVisible = useSelector((state) => state.channelDrawerVisible);
  const rightscrollEndTilePosition = useSelector((state) => state.rightscrollEndTilePosition);
  const rightscrollStartTilePosition = useSelector((state) => state.rightscrollStartTilePosition);
  const leftscrollStartTilePosition = useSelector((state) => state.leftscrollStartTilePosition);
  const leftscrollEndTilePosition = useSelector((state) => state.leftscrollEndTilePosition);

  const dispatch = useDispatch();
  let [tooltipImage, setTooltipImage] = useState(TOOLTIP_TEXTS.LEFT_RIGHT.img);
  let [tooltipTitleJSX, setTooltipTitkeJSX] = useState(TOOLTIP_TEXTS.LEFT_RIGHT.jsx_text);
  const [programInfoVisible, setProgramInfoVisible] = useState(1);
  const [toolTipVisible, setToolTipVisible] = useState(1);

  const listRef = useRef(null);
  const totalTranslate = useRef(0);
  const focusScrollTimer = useRef();
  const channels = props.channels;

  console.log(`got scheduleData ${scheduleData}`);
  let programInfoTimeEnded = 0;
  let drawerTimeout = useRef(null);
  let toolTipTimeout2 = useRef(null);
  let toolTipTimeout3 = useRef(null);
  let closeTooltiptimeout = useRef(null);
  let oneMinuteCheckTimeout = useRef(null);
  const currentTime = new Date().toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  const startDrawerTimeout = () => {
    clearTimeout(drawerTimeout.current);
    programInfoTimeEnded = 0;
    drawerTimeout.current = setTimeout(() => {
      if (channelDrawerVisible) {
        dispatch(setDrawerExitReason('Drawer timed out'));
        log(appEvents.DRAWER_EXIT);
      }
      dispatch(updateChannelDrawerVisible(0));

      dispatch(setScrollEndReason('Drawer timed out'));

      if (leftscrollStartTilePosition !== null) {
        dispatch(setLeftScrollEndTilePosition(focusIdx));
        log(appEvents.DRAWER_SCROLL_LEFT);
      }
      if (rightscrollStartTilePosition !== null) {
        dispatch(setRightScrollEndTilePosition(focusIdx));
        log(appEvents.DRAWER_SCROLL_RIGHT);
      }
      console.log('video start', videoStarted);
      setProgramInfoVisible(!videoStarted);
      programInfoTimeEnded = 1;
    }, config.TOOLTIP_TIMEOUT);
  };
  const changeChannel = (val, idx) => {
    dispatch(setPreviouslyRunningChannel(currentChannel));
    dispatch(updateCurrentChannel(val));
    dispatch(updateChannelIdx(idx));
    dispatch(updateFocusIdx(idx));
    localStorage.setItem('lastPlayedChannelId', val.service_id);
    if (config.DEBUG) console.log(val, 'New Set Channel');
  };
  const openAndThenCloseTooltipAfter5Secs = () => {
    if (firstTimeUserGuide === TOOLTIP_VIEWED) {
      return;
    }
    setToolTipVisible(0);
    setTimeout(() => {
      setToolTipVisible(1);
      console.log(toolTipVisible, tooltipTitle, 'one minute check Set Tooltip True');
      clearTimeout(closeTooltiptimeout.current);
      closeTooltiptimeout.current = setTimeout(() => {
        setToolTipVisible(0);
        console.log(toolTipVisible, tooltipTitle, 'one minute check Set Tooltip False');
      }, 5000);
    }, 0);
  };

  const updateToolTipTextAndSetViewedState = (data) => {
    if (firstTimeUserGuide === TOOLTIP_VIEWED) {
      return;
    }
    console.log(data, 'Set Tooltip Text');
    switch (data.text) {
      case TOOLTIP_TEXTS.SELECT_UP_DOWN.text:
        dispatch(updateTooltipTitle(TOOLTIP_TEXTS.SELECT_UP_DOWN.text));
        setTooltipImage(TOOLTIP_TEXTS.SELECT_UP_DOWN.img);
        setTooltipTitkeJSX(TOOLTIP_TEXTS.SELECT_UP_DOWN.jsx_text);
        dispatch(
          setOnBoardingStates({
            SELECT_UP_DOWN: {
              viewed: true
            }
          })
        );
        break;
      case TOOLTIP_TEXTS.SELECT.text:
        dispatch(updateTooltipTitle(TOOLTIP_TEXTS.SELECT.text));
        setTooltipImage(TOOLTIP_TEXTS.SELECT.img);
        setTooltipTitkeJSX(TOOLTIP_TEXTS.SELECT.jsx_text);

        dispatch(
          setOnBoardingStates({
            SELECT: {
              viewed: true
            }
          })
        );
        break;
      case TOOLTIP_TEXTS.CHANNEL_UP_DOWN.text:
        dispatch(updateTooltipTitle(TOOLTIP_TEXTS.CHANNEL_UP_DOWN.text));
        setTooltipImage(TOOLTIP_TEXTS.CHANNEL_UP_DOWN.img);
        setTooltipTitkeJSX(TOOLTIP_TEXTS.CHANNEL_UP_DOWN.jsx_text);

        dispatch(
          setOnBoardingStates({
            CHANNEL_UP_DOWN: {
              viewed: true
            }
          })
        );
        break;
      case TOOLTIP_TEXTS.LEFT_RIGHT.text:
        dispatch(updateTooltipTitle(TOOLTIP_TEXTS.LEFT_RIGHT.text));
        setTooltipImage(TOOLTIP_TEXTS.LEFT_RIGHT.img);
        setTooltipTitkeJSX(TOOLTIP_TEXTS.LEFT_RIGHT.jsx_text);

        dispatch(
          setOnBoardingStates({
            LEFT_RIGHT: {
              viewed: true
            }
          })
        );
        break;
      case TOOLTIP_TEXTS.UP_TO_SPEED.text:
        dispatch(updateTooltipTitle(TOOLTIP_TEXTS.UP_TO_SPEED.text));
        setTooltipImage(TOOLTIP_TEXTS.UP_TO_SPEED.img);
        setTooltipTitkeJSX(TOOLTIP_TEXTS.UP_TO_SPEED.jsx_text);

        dispatch(
          setOnBoardingStates({
            UP_TO_SPEED: {
              viewed: true
            }
          })
        );
        break;
      default:
        break;
    }
  };

  const initiateOneMinuteCheck = () => {
    dispatch(setOneMinuteCheck(true));
    clearTimeout(oneMinuteCheckTimeout.current);
    oneMinuteCheckTimeout.current = setTimeout(() => {
      dispatch(setOneMinuteCheck(false));
    }, 60000);
  };

  const showChannelTriggeredTooltip = () => {
    setToolTipVisible(0);
    if (!onBoardingStates.SELECT_UP_DOWN.viewed) {
      clearTimeout(toolTipTimeout3.current);
      toolTipTimeout3.current = setTimeout(() => {
        openAndThenCloseTooltipAfter5Secs();
        updateToolTipTextAndSetViewedState(TOOLTIP_TEXTS.SELECT_UP_DOWN);
        initiateOneMinuteCheck();
      }, 1000);
    }
  };

  const handleButtonDown = (event) => {
    if (exitPopup) return;
    startDrawerTimeout();
    if (event.key === 'XF86RaiseChannel' || event.key === 'u') {
      //CHANNEL_UP
      if (channelIdx < channels.length - 1) {
        dispatch(setChannelExitReason('Channel changed'));
        log(appEvents.END_CHANNEL);

        changeChannel(channels[channelIdx + 1], channelIdx + 1);
        setProgramInfoVisible(1);
        horizontalScroll(channelIdx + 1, 'Right');
        showChannelTriggeredTooltip();
        dispatch(setChannelEntryPoint('Channel changed'));
        log(appEvents.START_CHANNEL);
      }
    } else if (event.key === 'XF86LowerChannel' || event.key === 'd') {
      //CHANNEL_DOWN

      if (channelIdx > 0) {
        dispatch(setChannelExitReason('Channel changed'));

        log(appEvents.END_CHANNEL);

        changeChannel(channels[channelIdx - 1], channelIdx - 1);
        setProgramInfoVisible(1);
        horizontalScroll(channelIdx - 1, 'Left');
        showChannelTriggeredTooltip();
        dispatch(setChannelEntryPoint('Channel changed'));

        log(appEvents.START_CHANNEL);
      }
    }
    if (!active) return;
    if (event.key === 'ArrowRight' && focusIdx < channels.length - 1) {
      if (rightscrollStartTilePosition === null) {
        dispatch(setRightScrollStartTilePosition(focusIdx));
      }
      if (leftscrollStartTilePosition !== null) {
        dispatch(setLeftScrollEndTilePosition(focusIdx));
        dispatch(setScrollEndReason('Scroll right'));
        log(appEvents.DRAWER_SCROLL_LEFT);
      }
      dispatch(updateFocusIdx(focusIdx + 1));
      horizontalScroll(focusIdx + 1, 'Right');
      if (!onBoardingStates.SELECT.viewed) {
        openAndThenCloseTooltipAfter5Secs();
        updateToolTipTextAndSetViewedState(TOOLTIP_TEXTS.SELECT);
      }
    } else if (event.key === 'ArrowLeft' && focusIdx > 0) {
      if (leftscrollStartTilePosition === null) {
        dispatch(setLeftScrollStartTilePosition(focusIdx));
      }
      if (rightscrollStartTilePosition !== null) {
        dispatch(setRightScrollEndTilePosition(focusIdx));
        dispatch(setScrollEndReason('Scroll left'));
        log(appEvents.DRAWER_SCROLL_RIGHT);
      }
      dispatch(updateFocusIdx(focusIdx - 1));
      horizontalScroll(focusIdx - 1, 'Left');
      if (!onBoardingStates.SELECT.viewed) {
        openAndThenCloseTooltipAfter5Secs();
        updateToolTipTextAndSetViewedState(TOOLTIP_TEXTS.SELECT);
      }
    } else if (event.key === 'Enter') {
      dispatch(setScrollEndReason('Enter/Select'));

      if (leftscrollStartTilePosition !== null) {
        dispatch(setLeftScrollEndTilePosition(focusIdx));
        log(appEvents.DRAWER_SCROLL_LEFT);
      }
      if (rightscrollStartTilePosition !== null) {
        dispatch(setRightScrollEndTilePosition(focusIdx));
        log(appEvents.DRAWER_SCROLL_RIGHT);
      }
      dispatch(setChannelExitReason('Channel changed'));
      log(appEvents.END_CHANNEL);
      setToolTipVisible(0);
      dispatch(setCurrentlySelectedTilePosition(focusIdx));
      dispatch(setCurrentSelectedProgram(channels[focusIdx]));
      log(appEvents.DRAWER_CONTENT_SELECT, {
        channel: channels[focusIdx]
        // currentFocusIdx: focusIdx,
        // previousFocusIdx: null
      });
      changeChannel(channels[focusIdx], focusIdx);
      if (!onBoardingStates.CHANNEL_UP_DOWN.viewed) {
        openAndThenCloseTooltipAfter5Secs();
        updateToolTipTextAndSetViewedState(TOOLTIP_TEXTS.CHANNEL_UP_DOWN);
      }
      dispatch(setChannelEntryPoint('Channel changed'));
      log(appEvents.START_CHANNEL);
      if (channelDrawerVisible) {
        dispatch(setDrawerExitReason('Channel changed'));
        log(appEvents.DRAWER_EXIT);
      }
      dispatch(updateChannelDrawerVisible(0));
      setProgramInfoVisible(1);
    } else if (event.key === 'Backspace' || event.keyCode === 10009) {
      if (channelDrawerVisible) {
        dispatch(setDrawerExitReason('Return Key pressed'));
        log(appEvents.DRAWER_EXIT);
      }
      dispatch(setScrollEndReason('Backspace'));
      if (leftscrollStartTilePosition !== null) {
        dispatch(setLeftScrollEndTilePosition(focusIdx));
        log(appEvents.DRAWER_SCROLL_LEFT);
      }
      if (rightscrollStartTilePosition !== null) {
        dispatch(setRightScrollEndTilePosition(focusIdx));
        log(appEvents.DRAWER_SCROLL_RIGHT);
      }
      dispatch(updateChannelDrawerVisible(0));
      setProgramInfoVisible(0);
    }
  };
  const horizontalScroll = (idx, direction) => {
    const items = document.querySelectorAll('.listItem');
    const focusedItem = items[idx];
    const container = document.getElementsByClassName('channel-drawer')[0];
    const margin = items[1].offsetLeft - (items[0].offsetLeft + items[0].offsetWidth);

    if (focusedItem) {
      const containerLeft = container.offsetLeft;
      const containerWidth = container.offsetWidth;
      const containerRight = containerWidth + containerLeft;
      const focusedItemLeft = focusedItem.offsetLeft;
      const focusedItemWidth = focusedItem.offsetWidth;
      const focusedItemRight = focusedItemLeft + focusedItemWidth;
      if (direction === 'Right') {
        if (focusedItemRight > containerRight + totalTranslate.current + margin) {
          totalTranslate.current = focusedItemRight - containerRight + 2 * margin;
          listRef.current.style.transform = `translateX(-${totalTranslate.current}px)`;
          console.log('Drawer_Content_Impression tile', idx);
          log(appEvents.DRAWER_CONTENT_EXPRESSION, {
            channel: channels[idx],
            currentDrawerTilePosition: idx
          });
        }
        if (focusedItemLeft - totalTranslate.current < 0) {
          totalTranslate.current = focusedItemLeft;
          listRef.current.style.transform = `translateX(-${totalTranslate.current}px)`;
          console.log('Drawer_Content_Impression tile', idx);
          log(appEvents.DRAWER_CONTENT_EXPRESSION, {
            channel: channels[idx],
            currentDrawerTilePosition: idx
          });
        }
      } else if (direction === 'Left') {
        if (focusedItemLeft - totalTranslate.current < 0) {
          totalTranslate.current = focusedItemLeft;
          listRef.current.style.transform = `translateX(-${totalTranslate.current}px)`;
          console.log('Drawer_Content_Impression tile', idx);
          log(appEvents.DRAWER_CONTENT_EXPRESSION, {
            channel: channels[idx],
            currentDrawerTilePosition: idx
          });
        }
        if (focusedItemRight > containerRight + totalTranslate.current + margin) {
          totalTranslate.current = focusedItemRight - containerRight + 2 * margin;
          listRef.current.style.transform = `translateX(-${totalTranslate.current}px)`;
          console.log('Drawer_Content_Impression tile', idx);
          log(appEvents.DRAWER_CONTENT_EXPRESSION, {
            channel: channels[idx],
            currentDrawerTilePosition: idx
          });
        }
      }
    }
  };
  const deepLink = () => {
    const requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();
    if (requestedAppControl) {
      const appControlData = requestedAppControl.appControl.data;

      for (var i = 0; i < appControlData.length; i++) {
        if (appControlData[i].key == 'PAYLOAD') {
          const actionData = JSON.parse(appControlData[i].value[0]).values;
          // navigate to a specific channel or screen here
          let parsedActionData = actionData;
          console.log('deeplink is happening');
          console.log(parsedActionData, typeof parsedActionData, 'deeplink');
          let index = SERVICE_IDS_TO_SHOW.indexOf(actionData);
          console.log('deeplink indx', index);
          changeChannel(channels[index], index);
        }
      }
    } else {
      console.log('no req app control');
    }
  };
  useEffect(() => {
    if (active || programInfoVisible) {
      log(appEvents.CHANNEL_PAGE);
    }
  }, [active, programInfoVisible]);
  useEffect(() => {
    clearTimeout(focusScrollTimer.current);
    focusScrollTimer.current = setTimeout(() => {
      horizontalScroll(channelIdx, 'Right');
      dispatch(updateFocusIdx(channelIdx));
    }, 800);
  }, [active]);
  // const handleAppGroundCheck = () => {
  //   if (document.visibilityState) {
  //     webapis.avplay.close();
  //     if (webapis.sso.getLoginStatus() === 0) {
  //       dispatch(updateIsLoginPageVisible(0));
  //     }
  //     log(appEvents.EXIT_APP);
  //   } else {
  //     // app is in the foreground
  //     log(appEvents.START_APP);
  //   }
  //   console.log(
  //     'visibility change hidden channel drawer document.hidden',
  //     document.hidden,
  //     document.visibilityState
  //   );
  // };
  // useEffect(() => {
  //   const [noded] = document.getElementsByClassName('channel-drawer');
  //   console.log(noded, 'noded');
  //   noded.addEventListener('visibilitychange', handleAppGroundCheck);
  //   console.log('hidden console');
  //   horizontalScroll(channelIdx, 'Right');
  //   return () => {
  //     noded.removeEventListener('visibilitychange', handleAppGroundCheck);
  //   };
  // }, []);
  useEffect(() => {
    if (programInfoTimeEnded) {
      setProgramInfoVisible(!videoStarted);
    }
    if (showUpSpeedTooltip) {
      if (
        firstTimeUserGuide !== TOOLTIP_VIEWED &&
        oneMinuteCheck === true &&
        !onBoardingStates.UP_TO_SPEED.viewed &&
        onBoardingStates.CHANNEL_UP_DOWN.viewed &&
        onBoardingStates.LEFT_RIGHT.viewed &&
        onBoardingStates.SELECT.viewed &&
        onBoardingStates.SELECT_UP_DOWN.viewed
      ) {
        openAndThenCloseTooltipAfter5Secs();
        updateToolTipTextAndSetViewedState(TOOLTIP_TEXTS.UP_TO_SPEED);
        dispatch(updateShowUpSpeedTooltip(0));
        let state = TOOLTIP_VIEWED;
        localStorage.setItem('firstTimeUserGuide', state);
        console.log(localStorage.getItem('firstTimeUserGuide'), 'firstTimeUserGuide localStorage');
      }
    }
    startDrawerTimeout();
    document.addEventListener('appcontrol', deepLink);
    document.addEventListener('keydown', handleButtonDown);
    return () => {
      clearTimeout(drawerTimeout);
      document.removeEventListener('keydown', handleButtonDown);
      document.removeEventListener('appcontrol', deepLink);
    };
  }, [active, props.active, focusIdx, channelIdx, videoStarted]);

  useEffect(() => {
    channels.map((element, idx) => {
      let schedule = Array.isArray(scheduleData)
        ? scheduleData.find((s) => s.service_id === element.service_id)
        : null;
      if (schedule) {
        console.log(`got schedule`);
        const currentTime = new Date();
        let startTime, endTime, duration;

        for (let x in schedule.content) {
          startTime = new Date(schedule.content[x].starttime);
          duration = +schedule.content[x].duration;
          endTime = new Date(startTime.getTime() + duration * 1000);
          if (currentTime >= startTime && endTime > currentTime) {
            if (idx === focusIdx) {
              dispatch(setCurrentFocusedProgram(schedule.content[x]));
            }
            break;
          }
        }
      }
      if (idx === focusIdx) {
        dispatch(setCurrentlyFocusedChannel(element));
      }
    });
  }, [focusIdx]);
  useEffect(() => {
    console.log(
      'visibility change hidden document.hidden',
      document.hidden,
      document.visibilityState
    );
    if (active) {
      log(appEvents.DRAWER_START);
    } else {
      log(appEvents.CHANNEL_PREVIEW_PAGE);
    }
  }, [active]);

  useEffect(() => {
    clearTimeout(toolTipTimeout2.current);
    toolTipTimeout2.current = setTimeout(() => {
      if (active && !onBoardingStates.LEFT_RIGHT.viewed) {
        openAndThenCloseTooltipAfter5Secs();
        console.log('onBoardingStates', onBoardingStates, active);
        updateToolTipTextAndSetViewedState(TOOLTIP_TEXTS.LEFT_RIGHT);
      }
    }, 1000);
  }, [active]);

  console.log(`rendering channel drawer`);
  return (
    <div className="wrapper">
      <div className={active ? 'background-gradient visible' : 'background-gradient hide'}></div>
      <div className={`${'channel-drawer'} ${active || programInfoVisible ? 'pgbg' : ''}`}>
        <ProgramInfo
          className={active || programInfoVisible ? 'visible' : 'hide'}
          focusIdx={focusIdx}
          iconUrl={channels[channelIdx]?.icons['70']}
          channelName={channels[channelIdx]?.name}
          key={channels[channelIdx]?.name}></ProgramInfo>
        <ToolTip
          className={
            tooltipTitle !== '' && toolTipVisible && firstTimeUserGuide !== TOOLTIP_VIEWED
              ? 'visible'
              : 'hide'
          }
          text={tooltipTitle}
          jsx_text={tooltipTitleJSX}
          icon={tooltipImage}></ToolTip>

        <div className={active ? 'channel-container visible' : 'channel-container hide'}>
          <div className="info">
            <div className="samsung-news-logo">
              <img src={samsungNewsLogo} alt="" />
            </div>
            <div className="geo-info">
              <div className="current-time">{currentTime}</div>
              <div className="weather">
                <div className="weather-icon">
                  <img src={weatherIcon} alt="" />
                </div>
                <span className="temparature">{props.weatherInfo.temperature}&deg; </span>
                <span className="location">
                  {props.weatherInfo.city}, {stateNameToAbbreviation(props.weatherInfo.state)}
                </span>
              </div>
            </div>
          </div>
          <ul className="channel-list" ref={listRef}>
            {channels.map((element, idx) => {
              let schedule = Array.isArray(scheduleData)
                ? scheduleData.find((s) => s.service_id === element.service_id)
                : null;
              if (schedule) {
                const currentTime = new Date();
                let startTime, endTime, duration;

                for (let x in schedule.content) {
                  startTime = new Date(schedule.content[x].starttime);
                  duration = +schedule.content[x].duration;
                  endTime = new Date(startTime.getTime() + duration * 1000);
                  if (currentTime >= startTime && endTime > currentTime) {
                    if (idx === focusIdx) {
                      // dispatch(setCurrentFocusedProgram(schedule.content[x]));
                    }
                    // programTitle = schedule.content[x].title;
                    // progressBarWidth = (currentTime - startTime) / (duration * 1000);
                    // programPoster = schedule.content[x]?.poster?.url?.replace('%02d', '20');
                    break;
                  }
                }
              }
              if (idx === focusIdx) {
                // dispatch(setCurrentlyFocusedChannel(element));
              }
              // <ChannelItem active={active} isFocused={idx === focusIdx} element={element} />;
              return (
                <ChannelItem
                  active={active}
                  isFocused={idx === focusIdx}
                  element={element}
                  key={idx}
                  tileIdx={idx}
                  channels={props.channels}
                  schedule={schedule}
                  isPlaying={idx === channelIdx}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ChannelDrawer;
