const SERVICE_IDS_TO_SHOW = [
  'USBC39000171G', // ABC News Live
  'USBA300024TN', // Fox LiveNOW
  'USAJ3504714A', // Cheddar News
  'USBA300031OT', // Sportsgrid
  'USBA30002142', // TYT
  'USAJ4300005PJ', // Yahoo! Finance
  'USBC2400015DO', // Hollywire
  'USBA300032J7' // Fox Soul
];
export { SERVICE_IDS_TO_SHOW };
