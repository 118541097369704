import './loader.scss';
import Lottie from 'lottie-react';
import animationData from '../../assets/SP_News_Preloader_v2.json';
function Loader() {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <Lottie animationData={animationData} loop={true} />
      </div>
    </div>
  );
}
export default Loader;
