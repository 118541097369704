import React, { useEffect, useState } from 'react';
import './basic-popup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateExitPopup, setChannelExitReason } from '../../store/slices/common-slice';
import { log } from '../../logger';
import { eventNames as appEvent } from '../../config';
function BasicPopup(props) {
  const [btnFocusIdx, setBtnFocusIdx] = useState(0);
  //   const exitPopup = useSelector((state) => state.exitPopup);
  const dispatch = useDispatch();

  const handleButtonDown = async (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        if (btnFocusIdx > 0) {
          setBtnFocusIdx((val) => val - 1);
        }
        break;
      case 'ArrowRight':
        if (btnFocusIdx < props.buttonList?.length - 1) {
          setBtnFocusIdx((val) => val + 1);
        }
        break;
      case 'Enter':
        if (btnFocusIdx === 0) {
          dispatch(updateExitPopup(0));
        }
        if (btnFocusIdx === 1) {
          try {
            dispatch(setChannelExitReason('App Exited'));
            await log(appEvent.END_CHANNEL);
            await log(appEvent.EXIT_APP);
            dispatch(updateExitPopup(0));
          } catch (err) {
            console.error(err.message);
          } finally {
            tizen.application.getCurrentApplication().exit();
          }
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleButtonDown);
    return () => {
      document.removeEventListener('keydown', handleButtonDown);
    };
  }, [btnFocusIdx]);

  const buttonList = props.buttonList.map((item, idx) => {
    return (
      <div key={item} className={idx === btnFocusIdx ? 'buttonItem focused' : 'buttonItem'}>
        {item}
      </div>
    );
  });
  return (
    <div className="basic-popup">
      <div className="basic-popup-wrapper">
        <div className="basic-popup-header">
          <div className="title">Exit</div>
        </div>
        <div className="basic-popup-message">
          <div className="massege">Are you sure you want to exit Samsung News?</div>
        </div>
        <div className="basic-popup-buttons">{buttonList}</div>
      </div>
    </div>
  );
}

export default BasicPopup;
