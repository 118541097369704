import { epg, getSchedules, retrieveAndUnpackSchedule } from './platform';
import { SERVICE_IDS_TO_SHOW } from '../data/channels_to_include';
import config from '../config';

const processSchedule = (schedule) => {
  const channels = [];
  schedule.rsp.providers.forEach((provider) => {
    provider.channel.forEach((channel) => {
      // Skip any service ID's we aren't interested in
      if (!SERVICE_IDS_TO_SHOW.includes(channel.service_id)) {
        return;
      }

      channels.push(channel);
    });
  });

  console.log(`got schedules for ${channels.length} channels we were looking for`);
  console.log(channels, 'channel program schedule data');
  return channels;
};

export const scheduleDataRes = async () => {
  const channels = [];

  // get the schedules
  const start = new Date().getTime();
  const schedules = await getSchedules();
  if (schedules.rsp && schedules.rsp.stat === 'ok') {
    // find the first schedule where its start time + interval is less than the current time and its start time is before now

    const now = new Date();

    for (const s of schedules.rsp.schedule_url) {
      const scheduleFetchStart = new Date().getTime();

      const startTime = new Date(s.starttime);
      const endTime = new Date(startTime.getTime() + s.interval * 60 * 1000);

      if (endTime < now) {
        console.log(`skipping schedule which has already ended`);
        continue;
      }

      if (startTime < now && endTime > now) {
        console.log(`fetching current schedule`);
      }

      const scheduleData = await retrieveAndUnpackSchedule(s.url);

      const channelSchedules = processSchedule(scheduleData);

      for (const cs of channelSchedules) {
        const existingSchedules = channels.find((c) => c.service_id === cs.service_id);
        if (!existingSchedules) {
          // console.log(`channel ${cs.service_id} not already in list, adding it`);
          channels.push(cs);
        } else {
          // console.log(
          //   `channel ${cs.service_id} is in list, merging ${cs.content.length} new with ${existingSchedules.content.length} old`
          // );
          for (const program of cs.content) {
            existingSchedules.content.push(program);
          }
        }
      }

      const scheduleFetchElapsed = new Date().getTime() - scheduleFetchStart;
      console.log(`${scheduleFetchElapsed} ms to grab and unpack a schedule`);
    }

    const currentSchedule = (schedules.rsp.schedule_url || []).find((s) => {});
  }
  const elapsed = new Date().getTime() - start;
  console.log(`${elapsed} ms to retrieve schedules`);
  return channels;
};

export const epgData = async () => {
  let epgDataLocal = null;
  let data = await epg()
    .then((res) => {
      const connected = res.rsp.connected;
      // Filter channels in the order we have defined them in the array
      const channels = SERVICE_IDS_TO_SHOW.map((serviceID) =>
        connected.find((channel) => channel.service_id === serviceID)
      ).filter((channel) => channel !== undefined);
      // const channels = connected.filter((channel) =>
      //   SERVICE_IDS_TO_SHOW.includes(channel.service_id)
      // );
      if (config.DEBUG) console.log('before channels');
      console.log(`raw channels: `, channels);
      epgDataLocal = channels;
      for (let channel of epgDataLocal) {
        const tokenToReplace = '%02d';
        channel.icons = channel.station_img.index.split(',').reduce((acc, imageIdx) => {
          acc[imageIdx] = channel.station_img.url.replace(tokenToReplace, imageIdx);
          return acc;
        }, {});
      }
      return epgDataLocal;
    })
    .catch((err) => {
      console.log('epg api not working', err);
    });
  return data;
};
